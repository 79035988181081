import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    calander_time_period_init: {
        dashboardCalenderInit: {
            startdate: "",
            enddate: ""
        },
        growthPulsesCalenderInit: {
            startdate: "",
            enddate: ""
        },
        revenueProjectionCalenderInit: {
            startdate: "",
            enddate: ""
        },
        revenueInflowCalenderInit: {
            startdate: "",
            enddate: ""
        },
        salePurchaseCalenderInit: {
            startdate: "",
            enddate: ""
        },
        revenueManagementCalenderInit: {
            startdate: "",
            enddate: ""
        },
        saleAnalyticsCalenderInit: {
            startdate: "",
            enddate: ""
        }
    }
}

export const calanderTimePeriodSlice = createSlice({
    name: 'calanderTimePeriod',
    initialState,
    reducers: {
        setPeriodDashboardCalanderInit: (state, action) => {
            state.calander_time_period_init = {
                dashboardCalenderInit: action.payload,
                growthPulsesCalenderInit: action.payload,
                revenueProjectionCalenderInit: action.payload,
                revenueInflowCalenderInit: action.payload,
                salePurchaseCalenderInit: action.payload,
                revenueManagementCalenderInit: action.payload,
                saleAnalyticsCalenderInit: action.payload
            }
        },
        setPeriodChildCalanderInit: (state, action) => {
            state.calander_time_period_init = {
                ...state.calander_time_period_init,
                ...action.payload
            }
        }
    }
})

export const { setPeriodDashboardCalanderInit, setPeriodChildCalanderInit } = calanderTimePeriodSlice.actions

export default calanderTimePeriodSlice.reducer