import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import chat from '@src/pages/chat/store'
import commonReducer from './commonSlice'
import headerActionReducer from './headerActionSlice'
import bookReducer from './bookSlice'
import vendorReducer from './vendorReportSlice'
import subscriptionReducer from './subscriptionSlice'
import customerReducer from './customerReportSlice'
import einvoiceReducer from './einvoice'
import financialYearReducer from './financialYearSlice'
import supportChatReducer from './supportChatSlice'
import quickLinkSlice from './quickLinkSlice'
import inventorySettingReducer from './inventorySettingSlice'
import globalSearchReducer from './globalSearchSlice'
import webTitleReducer from './webTitleSlice'
import windowResizeReducer from './windowResizeSlice'
import listViewReducer from './listViewSlice'
import calanderTimePeriodSlice from './calanderTimePeriodSlice'
import toggleItemViewSlice from './toggleItemViewSlice'

const rootReducer = {
  auth,
  chat,
  navbar,
  layout,
  commonReducer,
  headerActionReducer,
  bookReducer,
  vendorReducer,
  subscriptionReducer,
  inventorySettingReducer,
  customerReducer,
  einvoiceReducer,
  financialYearReducer,
  supportChatReducer,
  quickLinkSlice,
  globalSearchReducer,
  webTitleReducer,
  windowResizeReducer,
  listViewReducer,
  calanderTimePeriodSlice,
  toggleItemViewSlice
}

export default rootReducer
