import React, { Component } from 'react'
import { ApiCall } from '../helper/axios'
import CommonApiEndPoint from '../helper/commonApiEndPoint'
import { getCookie, setCookie } from '../helper/commonFunction'

class Errorboundry extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false
        }
    }
    static async getDerivedStateFromError(error) {
        const header = { 'access-token': localStorage.getItem('access_token') }
        const user_data = JSON.parse(localStorage.getItem('userData'))
        const data = {
            user_id: user_data.user_id,
            log: error?.stack ? error.stack : error,
            module_type: window.location.pathname,
            table: 'error_react_boundary'
        }
        await ApiCall('POST', CommonApiEndPoint.error_handling, data, header)
        return {
            hasError: true
        }
    }
    render() {
        if (this.state.hasError) {
            if (window.location.host === 'app.themunim.com' && !getCookie('is_error')) {
                setCookie('is_error', 1, 60)
                location.reload(true)
            } else if (window.location.host !== 'app.themunim.com') {
                alert('Something went wrong so required reloading.')
                location.reload(true)
            }
            return
        }
        return this.props.children
    }
}

export default Errorboundry
